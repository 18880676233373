import {React} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Components/Home/Home';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import AccountDeleteForm from './Components/AccountDeleteForm/AccountDeleteForm';
import NotFound from "./NotFound/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />        
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/account-delete-form" element={<AccountDeleteForm/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;
