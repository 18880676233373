import { React} from "react";
import styles from "./AccountDeleteForm.module.css";

function AccountDeleteForm() {
    return (
        <div className={styles.div}>
            <h3 className={styles.h3}>
                Account Deletion Request
            </h3>
            <p className={styles.p}>
                To request for removal of your account from PaperBook app, please mail us at <a className={styles.a} href="mailto:support@auraindia.xyz?subject=Request for account deletion">support@auraindia.xyz</a>.
            </p>
        </div>
    )
}

export default AccountDeleteForm