import { React} from "react";
import styles from "./Home.module.css";
import comingsoon from '../../images/coming-soon.png';
import instagramLogo from '../../images/instagram.png';
import twitterLogo from '../../images/twitter.png';

function Home() {
    return (
        <div>
            <div className={styles.header}>
                <text>AuraIndia</text>
            </div>
            <div className={styles.column}>
                <div className={styles.container}>
                <img src={comingsoon} className={styles.comingSoon} alt="Coming Soon" />
                <div>
                    <p className={styles.h1}>We're thrilled to announce that we're working on a new website that we know you're going to love.</p>
                    <p>In the meantime, stay connected with us on social media for all the latest news and updates.</p>
                    <div className={styles.textContainer}>
                    <a href='https://www.instagram.com/auraindia.xyz/'><img src={instagramLogo} className={styles.instagram} alt="instagram.com/auraindia.xyz" /></a>
                    &emsp;
                    <a href='https://www.twitter.com/AuraIndia_/'><img src={twitterLogo} className={styles.twitter} alt="twitter.com/AuraIndia_" /></a>
                    </div>
                </div>
                </div>
                <text className={styles.aura}>© AuraIndia 2023 • &nbsp;<a href='https://github.com/abhinavsinghring'>Abhinav Singh</a></text>
            </div>
        </div>
    )
}

export default Home